import NEW_BREAKPOINTS from '@/constants/breakpoints';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';

const StyledProductsGrid = styled.div`
  gap: 24px;
  max-width: ${px2Rem(1200)};
  width: 100%;
  display: grid;

  @media (min-width: 786px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${NEW_BREAKPOINTS.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledGridWrapper = styled.div`
  display: block;
  padding: ${px2Rem(24)};

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 2fr;
  }

  @media (min-width: ${NEW_BREAKPOINTS.desktop}px) {
    grid-template-columns: 1fr 3fr;
  }
`;

const StyledProductsGridFilterWrapper = styled.div`
  display: none;

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    display: block;
  }
`;

export { StyledProductsGrid, StyledGridWrapper, StyledProductsGridFilterWrapper };
