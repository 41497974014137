import Spacer from '../../../elements/Spacer';
import { useProductsContext } from '../../../hooks/useProductsContext';
import FilterType from '../FilterType/FilterType';
import { StyledFilterWrapper, StyledFilterWrapperHeader } from './styles';
import { ITEM_CONDITIONS, filterProducts, getPriceRangeCount, getPriceRanges } from '../utils';
import Copy from '../../../elements/Copy';
import { primaryColors } from '../../../styles/variables/colours';
import { Container, Divider, Icon } from '@raylo-tech/raylopay-ui';
import { useAppContext } from '../../../hooks/useAppContext';
import SortMenu from '../SortMenu/SortMenu';
import { useCategoriesContext } from '../../../hooks/useCategoriesContext';
import { useCustomerContext } from '../../../hooks/useCustomerContext';
import { PreApprovedAmountBanner } from '../../AddTech';
import { conditionDisplayNames } from '../../../types/variantCondition';
import { unique } from '../../../utils/arrays.utils';

const FilterWrapper = () => {
  const { productCategories } = useCategoriesContext();
  const { availableProducts, filters } = useProductsContext();
  const { formattedPreApprovedAmount, eligibleForAddTech, preApprovedAmount } = useCustomerContext();
  const { setModalOpen } = useAppContext();

  const filterTypes = productCategories?.map((cat: { displayName: string; imageUrl: string }) => cat.displayName) ?? [];

  const models = unique(
    filterProducts(availableProducts, filters, ['category', 'condition', 'make'])
      .map(prod => prod.displayName)
      .filter((prod): prod is string => !!prod)
  );
  const makes = unique(
    filterProducts(availableProducts, filters, ['category', 'condition', 'model'])
      .map(prod => prod.make)
      .filter((prod): prod is string => !!prod)
  );

  const priceRanges = getPriceRanges(availableProducts, eligibleForAddTech);
  const priceRangeLabelMap = priceRanges.reduce(function (map: { [key: string]: string }, priceRange) {
    map[priceRange] = getPriceRangeCount(availableProducts, priceRange, preApprovedAmount ?? 0);
    return map;
  }, {});
  const optionAllPricesLabel = `All (${availableProducts.length})`;
  const preApprovedLabel = eligibleForAddTech && formattedPreApprovedAmount && (
    <PreApprovedAmountBanner formattedAmount={formattedPreApprovedAmount} shortVersion={true} />
  );

  return (
    <StyledFilterWrapper data-testid="desktop-filter-wrapper">
      <StyledFilterWrapperHeader>
        <Container flexRow alignCenter>
          <Copy color={primaryColors.c01} bold fontSize={24} dataTestId="filter-menu-copy">
            Filter & Sort
          </Copy>
          <div
            onClick={() => setModalOpen(null)}
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
            data-testid="filter-menu-icon"
          >
            <Icon name="RayloDesignCustomClose" $height={16} $width={16} />
          </div>
        </Container>
        <Spacer height={12} />
        <Divider height={24} />
        <SortMenu />
        <Spacer height={24} />
      </StyledFilterWrapperHeader>
      <FilterType filterType="category" items={filterTypes} />
      <Spacer height={24} />
      <FilterType filterType="make" items={makes} isLabel />
      {filters.category.length > 0 && (
        <>
          <Spacer height={24} />
          <FilterType filterType="model" items={models} />
        </>
      )}
      <Spacer height={24} />
      <FilterType filterType="condition" items={ITEM_CONDITIONS} labelMap={conditionDisplayNames} />
      <Spacer height={24} />
      <FilterType filterType="monthlyprice" items={priceRanges} labelMap={priceRangeLabelMap} optionAllLabel={optionAllPricesLabel}>
        {preApprovedLabel}
      </FilterType>
    </StyledFilterWrapper>
  );
};

export default FilterWrapper;
