import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { primaryColors } from '../../../styles/variables/colours';
import NEW_BREAKPOINTS from '@/constants/breakpoints';

export const StyledAppliedFiltersWrapper = styled.div<{ isOpen: boolean; maxHeight?: number }>`
  ${({ isOpen, maxHeight }) => `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: ${px2Rem(14)};
    width: 100%;
    max-height: ${isOpen ? `${maxHeight}px` : '0'};
    opacity: ${isOpen ? '1' : '0'};
    transition:
      max-height 0.5s ease ${isOpen ? '0s' : '0.2s'},
      opacity 0.5s ease ${isOpen ? '0.2s' : '0s'};

    @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: auto;
    }
  `}
`;

export const StyledAppliedFiltersClearAllButtonWrapper = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    order: 3;
  }
`;

export const StyledAppliedFiltersClearAllButton = styled.button`
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  color: ${primaryColors.b01};
  font-size: ${px2Rem(14)};
  line-height: ${px2Rem(24)};
  background: none;
  border: 0;
  text-wrap: nowrap;
  cursor: pointer;
`;

export const StyledAppliedFiltersCurrentFilters = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;

  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    width: auto;
    order: 2;
  }
`;
