import Copy from '../../../elements/Copy';
import { useProductsContext } from '../../../hooks/useProductsContext';
import { secondaryColors } from '../../../styles/variables/colours';

export const FilterProductCount = () => {
  const { filteredProducts } = useProductsContext();

  return (
    <Copy color={secondaryColors.c02} fontSize={14} lineHeight={20} dataTestId="filter-product-count">
      {filteredProducts.length} product{filteredProducts.length !== 1 && 's'}
    </Copy>
  );
};
