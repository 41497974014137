import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { primaryColors, secondaryColors } from '../../colors';

const StyledFilterLabel = styled.div<{
  $isSelected: boolean;
  $textTransform?: string;
  $backgroundColor?: string;
  $showClose?: boolean;
  $margin?: string;
}>`
  ${({ $isSelected, $textTransform, $backgroundColor, $showClose, $margin }) => `
    cursor: pointer;
    border-radius: ${px2Rem(16)};
    border: ${px2Rem(1)} solid ${secondaryColors.c03};
    color: ${primaryColors.c01};
    background-color: ${$backgroundColor ?? primaryColors.w01};

    ${!$showClose && `
      ${$isSelected ? (
        `
          border: ${px2Rem(1)} solid transparent;
          color: ${primaryColors.w01};
          background-color: ${$backgroundColor ?? primaryColors.b01};
          &:hover {
            background-color: ${secondaryColors.b02};
          }
        `
      ) : (
        `
          &:hover {
            background-color: ${secondaryColors.c05};
            border-color: ${secondaryColors.c04};
          }
        `
      )}
    `};

    ${$showClose && `
      &:hover {
        background-color: ${secondaryColors.c04};
        border-color: ${secondaryColors.c02};
      }
    `};
    width: fit-content;
    height: fit-content;
    padding: ${px2Rem(4)} ${px2Rem(12)};
    text-transform: ${$textTransform ?? 'none'};
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(20)};
    letter-spacing: ${$textTransform === 'uppercase' ? px2Rem(1) : 0};
    margin: ${$margin ?? `0 ${px2Rem(12)} ${px2Rem($showClose ? 0 : 12)} 0`};
    display: flex;
    align-items: center;
    white-space: nowrap;
  `}
`;

export { StyledFilterLabel };
