import { Fragment } from 'react';
import { Container, Spacer, FormCheckbox, FilterLabel } from '@raylo-tech/raylopay-ui';
import Copy from '../../../elements/Copy';
import { primaryColors } from '../../../styles/variables/colours';
import { FilterTypesProps, FilterIcon } from './types';
import { useProductsContext } from '../../../hooks/useProductsContext';
import { px2Rem } from '../../../utils/px2Rem';
import { isSelected } from '../utils';
import { StyledFilterBottomFade } from './styles';
import { capitalize } from '../../../utils/strings.utils';

const FilterType = ({ filterType, items, isLabel, children, labelMap, optionAllLabel = 'All' }: FilterTypesProps) => {
  const { toggleFilters, filters } = useProductsContext();
  const isPrice = filterType === 'monthlyprice';
  const isCategory = filterType === 'category';

  const showFade = !isLabel && items.length > 8;
  const fade = showFade && (<StyledFilterBottomFade />);

  return (
    <>
      <Copy fontSize={16} bold color={primaryColors.c01}>
        {isPrice ? 'Monthly Price' : capitalize(filterType)}
      </Copy>
      <Spacer height={8} />
      {children}
      {children && <Spacer height={8} />}
      <div style={{ position: 'relative' }}>
        <Container
          flexRow={isLabel}
          styles={{
            flexWrap: isLabel ? 'wrap' : 'nowrap',
            maxHeight: px2Rem(496),
            overflow: 'auto',
          }}
        >
          {!isLabel && (
            <>
              <FormCheckbox
                dataTestId={`checkbox-${filterType}-all`}
                label={optionAllLabel}
                onChange={() => toggleFilters(filterType, 'All')}
                checked={!filters[filterType]?.length}
                disabled={!filters[filterType]?.length}
              />
              <Spacer height={8} />
            </>
          )}
          {items.map((item, i) => {
            const checkboxLabel = labelMap?.[item] ?? item;
            let icon;

            if (isCategory) {
              icon = checkboxLabel as FilterIcon;
            }

            return (
              isLabel ?
                  (
                    <FilterLabel
                      dataTestId={`label-${filterType}-${item}`}
                      key={`${item}-${i}`}
                      copy={item}
                      isSelected={isSelected(filters[filterType], item)}
                      onSelect={() => toggleFilters(filterType, item)}
                      textTransform={filterType === 'make' ? 'uppercase' : 'capitalize'}
                    />
                  ) : (
                    <Fragment key={`${item}-${i}`}>
                      <FormCheckbox
                        dataTestId={`checkbox-${filterType}-${item}`}
                        label={checkboxLabel}
                        onChange={() => toggleFilters(filterType, item)}
                        checked={isSelected(filters[filterType], item)}
                        icon={icon}
                        size={20}
                      />
                      {i !== items.length - 1 && <Spacer height={8} />}
                    </Fragment>
                  )
            );
          })}
          {showFade && <Spacer height={36} />}
        </Container>
        {fade}
      </div>
    </>
  );
};
export default FilterType;
