import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useProductsContext } from '../../hooks/useProductsContext';
import ProductCard from '../productcard';
import { StyledGridWrapper, StyledProductsGrid, StyledProductsGridFilterWrapper } from './styles';
import { IProductsGrid } from './types';
import FilterWrapper from '../ProductFilter/FilterWrapper/FilterWrapper';
import FilterHeader from '../ProductFilter/FilterHeader/FilterHeader';
import { Container, Copy } from '@raylo-tech/raylopay-ui';
import { px2Rem } from '../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { useMediaLayout } from 'use-media';

export const ProductsGrid = ({ dataTestId }: IProductsGrid) => {
  const { filteredProducts } = useProductsContext();

  const isMobileOrTablet = useMediaLayout({ maxWidth: NEW_BREAKPOINTS.smallDesktop });
  const [scrolledPastGrid, setScrolledPastGrid] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null;

    const observer = new IntersectionObserver(([entry]) => {
      if (!isMobileOrTablet) return;
      setScrolledPastGrid(!entry.isIntersecting);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
      observerRefValue = containerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [containerRef, isMobileOrTablet]);

  return (
    <Container maxWidth={px2Rem(1200)} margin="0 auto">
      <FilterHeader scrolledPastGrid={scrolledPastGrid} />
      <StyledGridWrapper data-testid={dataTestId} ref={containerRef}>
        <StyledProductsGridFilterWrapper>
          <FilterWrapper />
        </StyledProductsGridFilterWrapper>
        {!filteredProducts.length && (
          <Copy styles={{ textAlign: 'center' }}>
            No results found. Please update your filters and try again.
          </Copy>
        )}
        <div>
          <StyledProductsGrid data-testid="products-grid">
            {filteredProducts?.map(
              (
                {
                  displayName,
                  variantImageUrl,
                  productVariantConditions,
                  variantSlug,
                  lowestMonthlyCost,
                  variantId,
                  cardDisplaySpecification,
                  make,
                  colours,
                  eligible,
                },
                productIndex
              ) => (
                <ProductCard
                  key={productIndex}
                  dataTestId={`product-card-${productIndex}`}
                  displayName={displayName}
                  imageUrl={variantImageUrl}
                  productVariantConditions={productVariantConditions}
                  lowestMonthlyCost={lowestMonthlyCost}
                  variantSlug={variantSlug}
                  cardDisplaySpecification={cardDisplaySpecification}
                  make={make}
                  variantId={variantId}
                  colours={colours}
                  eligible={eligible}
                  isPriorityImage={productIndex === 0}
                />
              )
            )}
          </StyledProductsGrid>
        </div>
      </StyledGridWrapper>
    </Container>
  );
};
