import { getImageProps } from 'next/image';
import Copy from '../../elements/Copy';
import { ColourSwatches, SkeletonLoadingBlock, Spacer } from '@raylo-tech/raylopay-ui';
import { Card } from './types';
import { StyledConditionBadge, StyledProductCard } from './styles';
import { cardImageSizes, getCardImageUrl } from './constants';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { VariantCondition } from '@/graphql/generated/graphql';
import { imgixLoader } from '../../utils/imgixLoader';

const ProductCardContent = ({
  productVariantConditions,
  make,
  displayName,
  cardDisplaySubtitle,
  url,
  displayPrice,
  colours,
  shouldDisplayPrices,
  variantSlug,
  isPriorityImage,
}: Card) => {
  const productCardImageSharedProps = {
    alt: displayName ?? '',
    loader: imgixLoader,
    priority: isPriorityImage,
    quality: 30,
  };

  /**
   * `next/image` likes to create a wide range of `srcSet` values, but we only
   * want to use the initial width and double the width, as we know that the
   * image will be displayed at that width on the page. Mobile is only
   * displayed at the `cardImageSizes.mobile.width` width, and desktop is only
   * displayed at the `cardImageSizes.desktop.width` width.
   *
   * This allows us to reduce the amount of HTML rendered for the PLP page, as
   * we only generate the exact srcSet values that are needed.
   */
  const createOverridingSrcSet = (url: string | undefined, device: 'mobile' | 'desktop') => {
    const initialWidth = cardImageSizes[device].width;

    /** Generate `1x` and `2x` image urls */
    return [initialWidth, initialWidth * 2].map((width) => {
      return [
        imgixLoader({
          src: getCardImageUrl(url, device),
          width: width,
          quality: productCardImageSharedProps.quality,
        }),
        `${width}w`,
      ].join(' ');
    }).join(', ');
  };

  const {
    props: desktopImageProps,
  } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.desktop.width,
    height: cardImageSizes.desktop.height,
    src: getCardImageUrl(url, 'desktop'),
    sizes: `${cardImageSizes.desktop.width}px`,
  });
  const {
    props: mobileImageProps,
  } = getImageProps({
    ...productCardImageSharedProps,
    width: cardImageSizes.mobile.width,
    height: cardImageSizes.mobile.height,
    src: getCardImageUrl(url, 'mobile'),
    sizes: `${cardImageSizes.mobile.width}px`,
  });

  /**
   * Use these srcSet values to override the `srcSet` values generated by
   * `next/image`
   */
  const mobileImageSrcSet = createOverridingSrcSet(url, 'mobile');
  const desktopImageSrcSet = createOverridingSrcSet(url, 'desktop');

  return (
    <StyledProductCard data-testid={`desktop-card-${variantSlug}`}>
      <div className="card-title">
        <Copy bold fontSize={16} center>
          {make}
        </Copy>
        <Copy bold fontSize={16} center>
          {displayName}
        </Copy>
      </div>
      {cardDisplaySubtitle && <Copy fontSize={14} center>{cardDisplaySubtitle}</Copy>}
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div className="image-container">
          <picture>
            <source media={`(min-width: ${NEW_BREAKPOINTS.smallDesktop}px)`} srcSet={desktopImageSrcSet} sizes={desktopImageProps.sizes} />
            <img {...mobileImageProps} srcSet={mobileImageSrcSet} alt={productCardImageSharedProps.alt} style={{ width: '100%', height: 'auto' }} />
          </picture>
        </div>
        {colours.length > 1 ? <ColourSwatches dataTestId="colour-swatches" colours={colours} className="colour-swatches" /> : <Spacer height={40} />}
        <div className="condition-badge-wrapper">
          {productVariantConditions.includes(VariantCondition.New) && (
            <StyledConditionBadge data-testid="condition-badge-new">
              Brand New
            </StyledConditionBadge>
          )}
          {productVariantConditions.includes(VariantCondition.Refurbished) && (
            <StyledConditionBadge data-testid="condition-badge-refurbished">
              Refurbished
            </StyledConditionBadge>
          )}
        </div>
        {shouldDisplayPrices ? (
          <div className="price-wrapper">
            <Copy bold fontSize={14} lineHeight={16} center>
              <span>From</span> £{displayPrice}/Mo
            </Copy>
          </div>
        ) : (
          <SkeletonLoadingBlock dataTestId={`${variantSlug}-loading`} $height={18} $borderRadius={5} />
        )}
      </div>
    </StyledProductCard>
  );
};

export default ProductCardContent;
