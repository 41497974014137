import type { NextPage } from 'next';
import { ProductsProvider } from '../../hooks/useProductsContext';
import { useAppContext } from '../../hooks/useAppContext';
import ProductsGrid from '../../components/productsgrid';
import { getProductsCardDataModel } from '../../utils/getProductsCardDataModel';
import FilterWrapperModal from '../../components/ProductFilter/FilterWrapperModal/FilterWrapperModal';
import {
  retrieveCategories,
  retrieveProducts
} from '../../utils/productsHelpers';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import { GET_PAGINATED_PRODUCTS_DETAILS } from '../../graphql/operations/getProducts.graphql';
import { AboutYourLimitModal, NotApprovedDeviceModal, PreApprovedHeaderBanner } from '../../components/AddTech';
import { buildErrorReported, captureBuildException } from '../../utils/sentry';
import { VariantForPLP } from '../../types/productTypes';
import { ProductsRepoProductCategoriesQuery } from '@/graphql/generated/graphql';
import PageMeta from '../../components/PageMeta';
import ProductsWrapper from '../../components/ProductsWrapper';
import IntercomWrapper from '@/components/IntercomWrapper/IntercomWrapper';
import { getNavDataModel } from '@raylo-tech/raylopay-ui';
import { Category, NavDataModel, ProductsQuery } from '@raylo-tech/raylopay-ui/src/types';

export type ProductsPageProps = {
  products: VariantForPLP[];
  /**
   * `categories` and `navData` are not used directly in this page, but instead is passed up
   * to `_app.tsx`, where it's then passed to `<CategoriesProvider />` via
   * the `pageProps`
   */
  categories: ProductsRepoProductCategoriesQuery;
  navData: NavDataModel[];
};

const Products: NextPage<ProductsPageProps> = ({ products }) => {
  const { modalOpen } = useAppContext();
  const { eligibleForAddTech, formattedPreApprovedAmount } = useCustomerContext();

  const showPreApprovedAmountBanner = eligibleForAddTech && formattedPreApprovedAmount;

  return (
    <>
      <PageMeta
        title="Products | Raylo"
        metaTitle="Choose from our range of brand new phones and refurbished phones"
        metaDescription="We offer both brand new phones and refurbished SIM-free phones"
        metaImage="https://assetsglobal.raylo.com/5ca6f1703977261264f0212e/5fb2416a21d82e24fe579714_5fb13d8e8e1c0c4b168a6284_open%20graph%20image.jpg"
      />
      <IntercomWrapper>
        <ProductsWrapper context="products">
          <ProductsProvider productData={products}>
            {showPreApprovedAmountBanner && <PreApprovedHeaderBanner formattedAmount={formattedPreApprovedAmount} />}
            <ProductsGrid />
            {modalOpen === 'filters' && <FilterWrapperModal />}
            <AboutYourLimitModal formattedAmount={formattedPreApprovedAmount} />
            <NotApprovedDeviceModal formattedAmount={formattedPreApprovedAmount} />
          </ProductsProvider>
        </ProductsWrapper>
      </IntercomWrapper>
    </>
  );
};

export async function getStaticProps() {
  if (buildErrorReported()) {
    console.log('Previously encountered a build error. Skipping build for products/index page');
    return {
      props: {},
    };
  }

  try {
    /**
     * `categories` is not used directly in this page, but instead is passed up
     * to `_app.tsx`, where it's then passed to `<CategoriesProvider />` via
     * the `pageProps`
     */
    const categories = await retrieveCategories();
    const rawProducts = await retrieveProducts(GET_PAGINATED_PRODUCTS_DETAILS);
    const products = getProductsCardDataModel(rawProducts);
    const navData = getNavDataModel(
      categories?.productCategories as Category[],
      rawProducts
    );

    if (!products || !categories) {
      return {
        props: {},
        notFound: true,
      };
    }

    return {
      props: {
        products,
        categories,
        navData,
      },
    };
  } catch (error) {
    await captureBuildException('products/index', error);
    throw error;
  }
}

export default Products;
